import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/Layout/Layout"
import Seo from "../../components/Seo"
import AudioPlayer from "react-h5-audio-player"
import PlayIcon from "../../components/svgs/PlayIcon"
import PauseIcon from "../../components/svgs/PauseIcon"
import VideoEmbed from "../../components/SingleTemplateComponents/VideoEmbed"
import EntryHeader from "../../components/SingleTemplateComponents/EntryHeader"
import ShareBar from "../../components/SingleTemplateComponents/ShareBar"

const sermon = ( { data: { sermon } } ) => {

	return (
		<Layout>
			<Seo
				title={sermon.title}
				canonical={sermon.seo?.canonical}
				uri={sermon.uri}
				socialImage={sermon.featuredImage?.node}
				description={sermon.seo?.metaDesc ? sermon.seo.metaDesc : sermon.excerpt.replace(/(<([^>]+)>)/gi, "") }
			/>

			{ sermon?.sermonMeta?.video ?
				<div>
					<VideoEmbed 
						video={sermon.sermonMeta.video}
					/>
				</div>

				:
				<div>
					{ sermon?.sermonMeta?.audiofile?.localFile?.publicURL &&
						<AudioPlayer
							src={sermon.sermonMeta.audiofile.localFile.publicURL}
							showSkipControls={false}
							showJumpControls={false}
							layout="horizontal"
							customIcons={ {play: <PlayIcon />, pause: <PauseIcon /> }}
						/>
					}
				
					{ ! sermon?.sermonMeta?.audiofile?.localFile?.publicURL && sermon?.sermonMeta?.audiofile?.mediaItemUrl &&
						<AudioPlayer
							src={sermon.sermonMeta.audiofile.mediaItemUrl}
							showSkipControls={false}
							showJumpControls={false}
							layout="horizontal"
							customIcons={ {play: <PlayIcon />, pause: <PauseIcon /> }}
						/>
					}
				</div>
			}
			<EntryHeader
				title={sermon.title}
				date={sermon?.sermonMeta?.displayDate ? sermon?.sermonMeta?.displayDate : sermon.date}
				icon={sermon?.organizations?.nodes[0]?.organizationMeta?.logo?.localFile}
				format='Sermon'
			/>

			<ShareBar 
				url={sermon.uri}
			/>
		
			<div
				className="entry-content max-w-2xl px-4 mx-auto mt-4 md:-mt-64 min-h-[40vh]"
				dangerouslySetInnerHTML={{ __html: sermon.content }}
			/>	
				
		</Layout>
	);
}

export const sermonQuery = graphql`
	query SermonById(
		$id: String!
	) {
		sermon: wpSermon( id: { eq: $id } ) {
			... SermonContent
		}
	}
`
export default sermon